import {del, get, post, put} from "./base";
import qs from "qs";
/**
 首页功能内容api
 */

// app首页内容获取
export function getAppContent(pageNum){
    return get('/v3/yun/contents',{pageNum})
}
// app首页内容排序
export function postAppContentMove(id,data){
    return post('/v3/yun/content/moveUpDown/'+id,qs.stringify(data))
}
export function delAppContent(id){
    return del('/v3/yun/content/'+id)
}
// 社区首页内容获取
export function getCommunityContent(pageNum,isOpen,title,time){
    return get('/v3/yun/community/content',{pageNum,isOpen,title,time})
}
//社区内容是否置顶
export function putCommunityContentTop(id,data){
    return put('/v3/yun/community/content/top/' +id,qs.stringify(data))
}
//社区内容是否上架下架
export function putCommunityContentOpen(id,data){
    return put('/v3/yun/community/content/' +id,qs.stringify(data))
}
//发布社区内容
export function postCommunityContent(data){
    return post('/v3/yun/community/content' ,data)
}
